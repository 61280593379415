import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2c20ac46"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "part-details-feedback-message-input" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.showBackButton && $setup.isInputFocused)
      ? (_openBlock(), _createBlock($setup["NButton"], {
          key: 0,
          class: "toggle-to-actions-button",
          circle: true,
          text: true,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('toggleToActions')))
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ChevronLeftInCircle"])
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock($setup["UserAvatar"], {
          key: 1,
          id: $setup.user.avatar?.id,
          size: 24,
          name: $setup.authorName
        }, null, 8, ["id", "name"])),
    _createVNode($setup["NFormItem"], {
      rule: $setup.messageRule,
      ref: "messageRef",
      class: _normalizeClass({ error: $setup.isMessageValidationError })
    }, {
      default: _withCtx(() => [
        _createVNode($setup["AutosizeTextarea"], {
          class: "message-input",
          modelValue: $setup.inputValue,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.inputValue) = $event)),
          placeholder: $props.placeholder,
          minHeight: 30,
          "validation-error": $setup.isMessageValidationError,
          onFocus: _cache[2] || (_cache[2] = ($event: any) => ($setup.onInputFocus())),
          onBlur: _cache[3] || (_cache[3] = ($event: any) => ($setup.onInputBlur()))
        }, null, 8, ["modelValue", "placeholder", "validation-error"]),
        _createVNode($setup["NButton"], {
          class: "send-message-button",
          circle: "",
          text: "",
          onClick: _cache[4] || (_cache[4] = ($event: any) => ($setup.onMessageSubmit())),
          disabled: $setup.isMessageValidationError || $setup.loading
        }, {
          default: _withCtx(() => [
            ($setup.loading)
              ? (_openBlock(), _createBlock($setup["NSpin"], {
                  key: 0,
                  size: "small"
                }))
              : (_openBlock(), _createBlock($setup["SendMessageIcon"], { key: 1 }))
          ]),
          _: 1
        }, 8, ["disabled"])
      ]),
      _: 1
    }, 8, ["class"])
  ]))
}